import Image from "next/image";
import Styles from "./styles.module.scss";
import ArrowLeft from '@/assets/img/rightArrow.svg';
import ArrowRight from '@/assets/icon/ArrowRight.svg';
import { Dispatch, SetStateAction } from "react";
import Link from "next/link";
import { NextRouter } from "next/router";

interface ProjectCardLPProps {
	bgImage: any;
	title: string;
	description: string;
	link: any;
	t: any
	setActiveCardCarousel?: Dispatch<SetStateAction<number>>,
	activeCardCarousel?: number
	carousel: number,
	acronym?: string,
	id?: string
	router?: NextRouter;
}

const ProjectCardLP = ({
	bgImage,
	title,
	description,
	link,
	t,
	setActiveCardCarousel,
	activeCardCarousel,
	carousel,
	acronym,
	id,
	router
}: ProjectCardLPProps) => {

	return (
		<div className={activeCardCarousel && activeCardCarousel > 0 ? Styles.containerProjectMain : Styles.containerMain}>
			<div className={activeCardCarousel && activeCardCarousel > 0 ? Styles.containerProjectImage : Styles.containerImage} >
				<Image src={bgImage} alt={title} layout="fill" objectFit="cover" />
			</div>
			<div className={activeCardCarousel && activeCardCarousel > 0 ? Styles.containerProjectText : Styles.containerText}>
				<h5>{title}</h5>
				{activeCardCarousel && activeCardCarousel > 0 && <span>#{acronym}</span>}
				<p>{description}</p>
			</div>
			{!(activeCardCarousel && activeCardCarousel > 0) ?
				<button className={Styles.buttonMore}
					type="button" onClick={() => setActiveCardCarousel && setActiveCardCarousel(carousel)}>
					{t.LP.SeeMore} <Image src={ArrowRight} alt={"Seta direita"} className={Styles.arrowRight} width={36} height={36} />
				</button>
				:
				<Link className={Styles.projectButton} href={`projeto/${title.toLowerCase().replace(/\s/g, "-")}`}
					onClick={() => localStorage.setItem("idProjectSelected", id!)} type="button">
					{t.LP.SeeMore} <Image src={ArrowRight} alt={"Seta direita"} className={Styles.arrowRight} width={36} height={36} />
				</Link>
			}
		</div>
	);
};

export default ProjectCardLP;
