import { useEffect, useState } from "react";
import Frame from "@/templates/Frame";
import Section from "@/components/organisms/Section";
import Paragrah from "@/components/atoms/Paragraph";
import Column from "@/components/molecules/Column";
import Head from "next/head";
import manImage from "@/assets/img/homemSorridente.webp";

// Static
import Styles from "./styles.module.scss";
import Button from "@/components/atoms/Button";

// languages
import en from "@/public/locales/en/common.json";
import pt from "@/public/locales/pt/common.json";
import es from "@/public/locales/es/common.json";
import { useRouter } from "next/router";
import { fetchDataUserInfo, getStatusBuyToken } from "@/utils/fetchDataAxios";
import Title from "@/components/atoms/Title";
import Separator from "@/components/atoms/Separator";
import Image from "next/image";
import InvestLP from "@/components/organisms/InvestLP/InvestLP";
import ProjectLP from "@/components/organisms/ProjectsLP/ProjectsLP";
import AboutLP from "@/components/organisms/AboutLP";

const QuemSomos = ({ envs }: any) => {
	const router = useRouter();

	const { locale } = router;

	function handleLanguage(locale: string) {
		switch (locale) {
			case "en":
				return en;
			case "pt":
				return pt;
			case "es":
				return es;
			default:
				return pt;
		}
	}
	const t = handleLanguage(locale ? locale : "pt");
	const [dataUser, setDataUser] = useState<any>();
	const [stateIdTransaction, setStateIdTransaction] = useState<string>("");

	useEffect(() => {
		let contador = 0;
		const token = localStorage.getItem("accessToken");
		const intervalCheckTransaction = setInterval(() => {
			contador += 1;
			const idTransactionLocalStorage = localStorage?.getItem("idTransaction");
			if (idTransactionLocalStorage) {
				setStateIdTransaction(JSON.parse(idTransactionLocalStorage));
				clearInterval(intervalCheckTransaction);
			} else if (contador === 5) {
				clearInterval(intervalCheckTransaction);
			}
		}, 2000);
		let logado = JSON.parse(localStorage.getItem("logado")!);
		if (typeof logado === "undefined") {
			fetchDataUserInfo(
				token,
				setDataUser,
				router,
				envs.NEXT_PUBLIC_GET_USER_INFO
			);
		} else {
			setDataUser(logado);
		}

		if (stateIdTransaction) {
			getStatusBuyToken(
				envs.NEXT_PUBLIC_GET_STATUS_BUY_TOKEN,
				stateIdTransaction,
				token
			);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router, stateIdTransaction]);

	return (
		<main>
			<Frame
				id="quem_somos"
				role="main"
				label="Página de sobre da CoinLivre"
				envs={envs}
			>
				<Head>
					<title>CoinLivre</title>
					<meta
						name="viewport"
						content="initial-scale=1.0, width=device-width"
					/>
					<meta name="theme-color" content="#00ee8d" />
					<meta name="robots" content="index, follow" />
					<meta name="description" content="CoinLivre | Quem Somos." />
				</Head>

				<Section
					id="introducao"
					label="introducao-title"
					desc="introducao-description"
					justify="between"
					hidden={false}
					className={`${Styles.background} min-vh-100 pt-5 pt-lg-0 d-flex align-items-center  `}
				>
					<Column
						media="lg"
						size={7}
						className={`pt-5 ${Styles.descriptionCoinLivre}`}
						maxWidth="675px"
					>
						<Separator color="#00ee8d" />
						<Title
							id="introducao-title"
							text={t.LP.yourAcessNewEconomy}
							hidden={false}
							width={50}
							weight={500}
							size={t === pt ? 70 : 60}
							className={Styles.title}
						/>
						<Paragrah
							id="introducao-description"
							text={t.LP.descriptionInvest}
							hidden={false}
							width={44}
							size={25}
							className={Styles.description}
						/>
						<div className={Styles.buttonLink}>
							<a href="#projetos">
								<Button
									hidden={false}
									id="buttonToProjects"
									label="Clique aqui para ir até os projetos"
									onClick={() => {}}
									text={t.LP.meetProjects}
									className={Styles.buttonProjects}
								/>
							</a>
						</div>
					</Column>
					<Image
						alt="Imagem de homem sorridente"
						src={manImage}
						className={`${Styles.manImage}`}
					/>

				</Section>

				<InvestLP t={t}/>
				<ProjectLP t={t}/>
				<AboutLP t={t}/>
			</Frame>
		</main>
	);
};

export async function getServerSideProps() {
	// Obtendo o valor da variável de ambiente usando process.env

	const {
		NEXT_PUBLIC_PROJETO_URL,
		NEXT_PUBLIC_PROJETO_ID_URL,
		NEXT_PUBLIC_GET_USER_INFO,
		NEXT_PUBLIC_GET_TRANSACTIONS,
		NEXT_PUBLIC_GERAR_PIX,
		NEXT_PUBLIC_COMPRAR_TOKEN,
		NEXT_PUBLIC_REQUEST_LOGIN,
		NEXT_PUBLIC_CADASTRAR_USER,
		NEXT_PUBLIC_GET_USER_CADASTRO,
		NEXT_PUBLIC_AUTH_LOGIN,
		NEXT_PUBLIC_UPLOAD_PROFILE_PHOTO,
		NEXT_PUBLIC_UPLOAD_DOCUMENTS_PROJETO,
		NEXT_PUBLIC_UPLOAD_BACKGROUND_PHOTO,
		NEXT_PUBLIC_CRIAR_PROJETO,
		NEXT_PUBLIC_UPLOAD_BACKGROUND_PROJETO,
		NEXT_PUBLIC_VERIFICATION_USER,
		NEXT_PUBLIC_SEND_EMAIL_FINISHED,
		NEXT_PUBLIC_SEND_EMAIL_APPROVED,
		NEXT_PUBLIC_SEND_EMAIL_REJECTED,
		NEXT_PUBLIC_VERIFICATION_ID_METAMAP,
		NEXT_PUBLIC_JWT_AUTH,
		NEXT_PUBLIC_SOCKET_URL,
		NEXT_PUBLIC_GET_STATUS_BUY_TOKEN,
	} = process.env;
	const envs = {
		NEXT_PUBLIC_SOCKET_URL: NEXT_PUBLIC_SOCKET_URL,
		NEXT_PUBLIC_AUTH_LOGIN: NEXT_PUBLIC_AUTH_LOGIN,
		NEXT_PUBLIC_CADASTRAR_USER: NEXT_PUBLIC_CADASTRAR_USER,
		NEXT_PUBLIC_COMPRAR_TOKEN: NEXT_PUBLIC_COMPRAR_TOKEN,
		NEXT_PUBLIC_CRIAR_PROJETO: NEXT_PUBLIC_CRIAR_PROJETO,
		NEXT_PUBLIC_GERAR_PIX: NEXT_PUBLIC_GERAR_PIX,
		NEXT_PUBLIC_GET_TRANSACTIONS: NEXT_PUBLIC_GET_TRANSACTIONS,
		NEXT_PUBLIC_GET_USER_CADASTRO: NEXT_PUBLIC_GET_USER_CADASTRO,
		NEXT_PUBLIC_GET_USER_INFO: NEXT_PUBLIC_GET_USER_INFO,
		NEXT_PUBLIC_PROJETO_ID_URL: NEXT_PUBLIC_PROJETO_ID_URL,
		NEXT_PUBLIC_PROJETO_URL: NEXT_PUBLIC_PROJETO_URL,
		NEXT_PUBLIC_REQUEST_LOGIN: NEXT_PUBLIC_REQUEST_LOGIN,
		NEXT_PUBLIC_SEND_EMAIL_APPROVED: NEXT_PUBLIC_SEND_EMAIL_APPROVED,
		NEXT_PUBLIC_SEND_EMAIL_FINISHED: NEXT_PUBLIC_SEND_EMAIL_FINISHED,
		NEXT_PUBLIC_SEND_EMAIL_REJECTED: NEXT_PUBLIC_SEND_EMAIL_REJECTED,
		NEXT_PUBLIC_UPLOAD_BACKGROUND_PHOTO: NEXT_PUBLIC_UPLOAD_BACKGROUND_PHOTO,
		NEXT_PUBLIC_UPLOAD_BACKGROUND_PROJETO:
			NEXT_PUBLIC_UPLOAD_BACKGROUND_PROJETO,
		NEXT_PUBLIC_UPLOAD_DOCUMENTS_PROJETO: NEXT_PUBLIC_UPLOAD_DOCUMENTS_PROJETO,
		NEXT_PUBLIC_UPLOAD_PROFILE_PHOTO: NEXT_PUBLIC_UPLOAD_PROFILE_PHOTO,
		NEXT_PUBLIC_VERIFICATION_ID_METAMAP: NEXT_PUBLIC_VERIFICATION_ID_METAMAP,
		NEXT_PUBLIC_VERIFICATION_USER: NEXT_PUBLIC_VERIFICATION_USER,
		NEXT_PUBLIC_JWT_AUTH: NEXT_PUBLIC_JWT_AUTH,
		NEXT_PUBLIC_GET_STATUS_BUY_TOKEN: NEXT_PUBLIC_GET_STATUS_BUY_TOKEN,
	};

	return {
		props: {
			envs,
		},
	};
}

export default QuemSomos;
